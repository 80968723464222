import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img alt="" src="/logo512.png" width={200}></img>
        <p>
          Welcome to LustJunkie
        </p>
      </header>
    </div>
  );
}

export default App;
